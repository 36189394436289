import React from "react"
import {Row, Col, Container, Jumbotron} from "react-bootstrap"
import SEO from "../components/seo"
import CovidMessage from "../components/covidMessage";
import ValuationCTA from "../components/valuationCta";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Img from "gatsby-image";
import CTAForm from "../components/ctaForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import setupIconLibrary from "../utils/iconLibrary"

setupIconLibrary()


const IndexPage = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "part-exchange-with-us"}}>
            <SEO title="Sell My Car Online | Buy My Car | iCashCars"/>

            <CovidMessage></CovidMessage>

            <Jumbotron id="home" className="bg-primary pt-lg-7 pb-lg-6 text-center mb-0 bg-tyre px-0">
                <Container className="position-relative">
                    <h1 className="display-2 font-weight-700 mb-4 text-white text-center text-uppercase">Part exchange
                        your car with us</h1>

                    <CTAForm></CTAForm>

                    <Row className="mt-6">
                        <Col lg={4} className="mb-4 mb-lg-0">
                            <Row>
                                <Col xs={3}>
                                    <Img fixed={data.iconCheck.childImageSharp.fixed}/>
                                </Col>
                                <Col xs={9} className="text-left">
                                    <h3 className="h4 font-weight-900 text-white mb-2">Free Instant estimate</h3>
                                    <p className="text-white mb-0">
                                        Enter your details to get a free estimate for your vehicle.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="mb-4 mb-lg-0">
                            <Row>
                                <Col xs={3}>
                                    <Img fixed={data.iconGlass.childImageSharp.fixed}/>
                                </Col>
                                <Col xs={9} className="text-left">
                                    <h3 className="h4 font-weight-900 text-white mb-2">Car inspection</h3>
                                    <p className="text-white mb-0">
                                        Inspection of your car taken place at your home, at your convenience.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="mb-4 mb-lg-0">
                            <Row>
                                <Col xs={3}>
                                    <Img fixed={data.iconHand.childImageSharp.fixed}/>
                                </Col>
                                <Col xs={9} className="text-left">
                                    <h3 className="h4 font-weight-900 text-white mb-2">Money in the bank</h3>
                                    <p className="text-white mb-0">
                                        Happy with the offer? Money will be transferred immediately.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>

            <Container className="slice border-top border-primary">
                <h2 className="text-center font-weight-800 mb-5">Selling Is Easy With Our Site</h2>
                <Row className="mb-3">
                    <Col lg={6}>
                        <h4 className="font-weight-800 text-info">
                            <FontAwesomeIcon icon="chevron-circle-right" className="mr-2"/>
                            We pay MORE for your car</h4>
                        <p>When you visit our site, you will see that it's very easy to get started. All you need to do
                            is enter your registration number to get started. You'll then be asked a few very simple
                            questions about your vehicle, such as the mileage, service history and condition of the car.
                            Once you've done that, enter a few personal details and then you'll receive your quote. It’s
                            astoundingly easy!
                        </p>
                    </Col>
                    <Col lg={6}>
                        <h4 className="font-weight-800 text-info">
                            <FontAwesomeIcon icon="chevron-circle-right" className="mr-2"/>
                            A Member Of Our Team Of Local Experts Will Come and Inspect Your Car
                        </h4>
                        <p>When you've accepted your quote, we'll send out one of our local experts to check over your
                            car. We work with independent experts all over the UK, so we can send someone out to you as
                            soon as possible. This expert will ensure your car is in the right condition, and that they
                            can honor the quote you received online.
                        </p>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg={6}>
                        <h4 className="font-weight-800 text-info">
                            <FontAwesomeIcon icon="chevron-circle-right" className="mr-2"/>
                            Don't Worry About The Paperwork
                        </h4>
                        <p>With the sale of any car, there will be DVLA paperwork to fill in. Even seasoned drivers can
                            be confused by what exactly needs to be filled in and sent off, but with us, you won't have
                            to worry about it. We'll take the paperwork away, give you what you need, and send the rest
                            off for you. Once your car has been sold you won't have to worry about a thing.
                        </p>
                    </Col>
                    <Col lg={6}>
                        <h4 className="font-weight-800 text-info">
                            <FontAwesomeIcon icon="chevron-circle-right" className="mr-2"/>
                            Sell Any Car To Us
                        </h4>
                        <p>Selling your car is a journey fraught with issues, but not if you sell to us. We will
                            consider buying any car, and we strive to make the process as painless as possible. If your
                            car is roadworthy, we'll happily take it off your hands. Check on iCashCars today to see how
                            much you can get for your car.
                        </p>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg={6}>
                        <h4 className="font-weight-800 text-info">
                            <FontAwesomeIcon icon="chevron-circle-right" className="mr-2"/>
                            Get More Money For Your Car
                        </h4>
                        <p>With so many car buying services online, why should you choose iCashCars? We'll give you more
                            money than any other buying service. You know what your car is worth, and you want to get a
                            fair price for your vehicle. We know that, so we'll do our very best to get you the most
                            amount possible for your car. Please remember though, it is a car traders website so don’t
                            be offended if you aren’t offered retail money for your vehicle. Selling your car has never
                            been quicker or simpler than it is with icashcars.co.uk
                        </p>
                    </Col>
                </Row>

            </Container>

            <ValuationCTA></ValuationCTA>

        </Layout>
    )
}


export const data = graphql`
    query {
      iconCheck: file(relativePath: { eq: "icons/icon-check.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
      iconGlass: file(relativePath: { eq: "icons/icon-glass.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
      iconHand: file(relativePath: { eq: "icons/icon-hand.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }


      iconDb: file(relativePath: { eq: "icons/icon-db.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
      iconWallet: file(relativePath: { eq: "icons/icon-wallet.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
      iconHandshake: file(relativePath: { eq: "icons/icon-handshake.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
      iconPaper: file(relativePath: { eq: "icons/icon-paper.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }
        iconCar: file(relativePath: { eq: "icons/icon-car.png" }) {
          childImageSharp {
            fixed {
                    ...GatsbyImageSharpFixed
              }
          }
      }

    }
`

export default IndexPage
